<template>
  <div>    
    
    <b-row>
      <b-col lg="12">
        <b-table
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          stacked="md"
          show-empty
          small
          @filtered="onFiltered"
          :tbody-tr-class="rowClass"
        >
          <template #cell(name)="row">{{ row.value }}</template>

          <template #cell(actions)="row">
            <b-button
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
            >Abrir detalle</b-button>
            <b-button
              size="sm"
              @click="row.toggleDetails"
            >{{ row.detailsShowing ? 'ocultar' : 'más' }} información</b-button>
          </template>

          <!-- <template #row-details="row">
            <b-card>
              <b-row>
                <b-col lg="12" class="badge-props">
                  <div>
                    <CBadge
                      color="success"
                      class="float-left"
                    >Servicios completados: {{ row.item[34] }}</CBadge>
                  </div>
                </b-col>
                <b-col lg="12" class="badge-props">
                  <div>
                    <CBadge
                      color="danger"
                      class="float-left"
                    >Servicios incompletos - sin finalizar: {{ row.item[2] }}</CBadge>
                  </div>
                </b-col>
                <b-col lg="12" class="badge-props">
                  <div>
                    <CBadge
                      color="warning"
                      class="float-left"
                    >Servicios sin ejecutar - no iniciados: {{ row.item[1] }}</CBadge>
                  </div>
                </b-col>
               
                <b-col lg="12">
                  <div>
                    <strong>Servicios cancelado por lluvia:</strong>
                    {{ row.item[67] }}
                  </div>
                </b-col>
                <b-col lg="12">
                  <div>
                    <strong>Servicios cancelado por cliente:</strong>
                    {{ row.item[68] }}
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </template> -->
        </b-table>
      </b-col>
    </b-row>      
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: "walker-services",  
  data() {
    return {
      fields: [
        { key: "fecha_inicio", label: "Fecha" },
        { key: "mascota", label: "Mascota" },
        { key: "obs_cliente", label: "Observaciones" }
			],
			totalRows: 1,
			currentPage: 1,
			perPage: 10,
			pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
			sortBy: "",
			sortDesc: false,
			sortDirection: "asc",
			filter: null,
      filterOn: [],  
      services: [],
      items: [],
    };
  },
  created() {    
    if(this.$route.params != null){
      this.services = JSON.parse(this.$route.params.services)      
      this.services.map((service) => {
        let item = {          
          fecha_inicio: moment(service.fecha_inicio).format('dddd, DD/MMMM/YYYY hh:mm a'),
          mascota: service.mascota.nombre,
          cliente: service.mascota.cliente.nombre,
          obs_cliente: service.obs_cliente,
        }
        this.items.push(item)
      })
    }
	},

  methods: {    
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    rowClass(item, type) {      
      if (!item || type !== "row") return;
      if (item[2] > 0) return "table-danger";
      if (item.name == "Sin recibo transacción") return "table-info";
    }
  }
};
</script>
